.navlinkh {
    text-decoration: none; 
    color: inherit; 
  }
  
  .navlinkh:hover {
    text-decoration: underline; 
    background: #efefef; 
  }

  .navlink {
    text-decoration: none; 
    color: inherit; 
  }

  .download-container {
    padding: 4rem; 
    width: 1200px
  }

  .download-wrapper {
    position: absolute;
    left: -9999px;
  }

  .pdf-page {
    page-break-before: always;
    padding: 4rem
  }

  .react-tagsinput {
    background-color: unset !important;
  }
  
  @media print {
    .pdf-page {
      page-break-before: always;
      padding: 4rem
    }
    .page-break {
      page-break-before: always;
    }
  }